<template>
  <StdPageContainer :class="{ hasmask: red_packet_status != 1 }" type="tabs">
    <template slot="title">
      <!-- 标题栏 -->
      <StdPageTitle title="现金红包">
        <div slot="content">
          1.达到指定观看时长后，将有机会获得现金红包；观看直播+回放均可支持领取红包，需要单独进行设置
          <br />
          2.观看时长定义：观看时长达到指定时间即可领取红包，观看时长定义：同一个观众在直播间的累计时长，观众退出直播间重新进入或刷新直播间，观看时长将累计计算。需要注意：更换观众身份将重新计算观看时长
          <br />
          3.领取红包人数数据更新频率：10秒，建议在在活动结束后查看完整数据
        </div>
        &nbsp;
        <vh-switch
          v-model="red_packet_status"
          :active-value="1"
          @change="protocolChange"
          :inactive-value="0"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          :active-text="
            (red_packet_status ? '已开启' : '开启后') +
            '，可使用「现金红包」功能，达到提升观众停留在直播间的时长'
          "
        ></vh-switch>
      </StdPageTitle>
    </template>

    <!-- 内容区 -->
    <template slot="content">
      <begin-play :webinarId="$route.params.id"></begin-play>
      <vh-dialog :visible.sync="dialogVisible" :append-to-body="true" title="提示" width="400px">
        <div class="cash-redpacket-dialog-content">
          <p>开启失败！</p>
          <p>请检查以下内容是否符合要求：</p>
          <p>
            1.是否将服务号授权给微吼
            <span class="success" v-if="bound_wechat == 1">已授权</span>
          </p>
          <p>
            2.是否开通微信商户号{{ bound_pay_info }}
            <span class="success" v-if="bound_pay_info == 1">已开通</span>
          </p>
          <p>3.微信商户号是否开通商家转帐能力</p>
          <p style="margin-top: 10px">
            <a href="https://saas-doc.vhall.com/opendocs/show/1772" target="_blank" class="link">
              点击查看操作手册 >
            </a>
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
          <vh-button round type="primary" @click="dialogVisible = false">知道了</vh-button>
        </span>
      </vh-dialog>
      <!-- tabs -->
      <vh-tabs v-model="activeTab" @tab-click="handleTabClick">
        <vh-tab-pane label="直播红包" name="1">
          <RedPacketList
            v-if="activeTab == 1"
            :webinarId="$route.params.id"
            :type="1"
            @edit="toEdit"
            @toRecord="toRecord"
            class="red-packet-list"
          ></RedPacketList>
        </vh-tab-pane>
        <vh-tab-pane label="回放红包" name="2">
          <RedPacketList
            v-if="activeTab == 2"
            :webinarId="$route.params.id"
            :type="2"
            @edit="toEdit"
            @toRecord="toRecord"
            class="red-packet-list"
          ></RedPacketList>
        </vh-tab-pane>
      </vh-tabs>
    </template>
  </StdPageContainer>
</template>
<script>
  import { StdPageContainer, StdPageTitle } from '@vhcl/ui-console';
  import { RedPacketList } from '@vhcl/red-packet';
  import { sessionOrLocal } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  export default {
    name: 'CashPacketList',
    components: {
      StdPageContainer,
      StdPageTitle,
      RedPacketList,
      beginPlay
    },
    data() {
      return {
        red_packet_status: 0,
        activeTab: this.$route.query.tab || '1',
        dialogVisible: false,
        bound_pay_info: 0,
        bound_wechat: 0
      };
    },
    methods: {
      protocolChange(val) {
        this.$fetch('setRedPacketSwitch', {
          webinar_id: this.$route.params.id,
          red_packet_status: val,
          business_uid: JSON.parse(sessionOrLocal.get('userId'))
        })
          .then(res => {
            if (res.code === 200) {
              this.red_packet_status = val;
            } else {
              this.red_packet_status = val == 1 ? 0 : 1;
              this.$message.error(res.msg);
              if (res.code == '513650') {
                this.dialogVisible = true;
                this.bound_pay_info = res.data.bound_pay_info;
                this.bound_wechat = res.data.bound_wechat;
              }
            }
          })
          .catch(error => {
            if (error.code == '513650') {
              this.dialogVisible = true;
              this.bound_pay_info = error.data.bound_pay_info;
              this.bound_wechat = error.data.bound_wechat;
            }
            this.red_packet_status = val == 1 ? 0 : 1;
            this.$message.error(error.msg);
          });
      },
      handleTabClick() {},
      toRecord(data) {
        this.$router.push({
          path: `/live/CashPacket/record/${this.$route.params.id}/${data.id}`
        });
      },
      toEdit(data) {
        this.$router.push({
          path: `/live/CashPacket/edit/${this.$route.params.id}`,
          query: data
            ? {
                id: data.id,
                type: Number(this.activeTab)
              }
            : {
                type: Number(this.activeTab)
              }
        });
      },
      getRedPacketSwitch() {
        this.$fetch('getRedPacketSwitch', { webinar_id: this.$route.params.id }).then(res => {
          if (res.code === 200) {
            this.red_packet_status = res.data.red_packet_status;
          }
        });
      }
    },
    created() {
      this.getRedPacketSwitch();
    }
  };
</script>
<style scoped lang="less">
  .red-packet-list {
    min-height: 600px;
  }
  .hasmask {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 45px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
</style>
<style lang="less">
  .cash-redpacket-dialog-content {
    line-height: 22px;
    color: #262626;
    .success {
      color: #0fba5a;
    }
  }
</style>
